html {
  width: 100%;
  height: 100vh;
}

body {
  width: 100%;
  min-height: 100vh;
}

body #root {
  width: 100%;
  height: 100%;
}

body iframe {
  border-width: 0px;
}

::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #29314f;
}

a {
  text-decoration-color: #5669dc;
}
